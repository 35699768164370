const _eventCategories = {
  concerts: {
    id: 5,
    tags: [
      'ambient',
      'blues',
      'chanson',
      'classical',
      'folk',
      'country',
      'dance',
      'electro_swing',
      'electronic',
      'experimental',
      'folklore',
      'funk',
      'gospel',
      'party_grime',
      'hardcore',
      'hip_hop',
      'indie',
      'concerts_industrial',
      'jazz',
      'k_pop',
      'latin_american',
      'metal',
      'new_age',
      'noise',
      'pop',
      'post_punk',
      'punk',
      'r_n_b',
      'rap',
      'reggae',
      'concerts_release_party',
      'rock',
      'ska',
      'soul',
      'swing',
      'trap',
      'trip_hop',
      'jungle',
      'psychedelic',
      'music_film',
      'play_opera',
      'party_dub',
      'jam_session',
    ],
  },
  play: {
    id: 6,
    tags: [
      'play_alternative',
      'play_ballet',
      'play_black',
      'play_cabaret',
      'comedy',
      'play_new_circus',
      'play_dance',
      'drama',
      'fairy_tale',
      'play_for_schools',
      'play_improvisation',
      'play_literature',
      'play_magic',
      'play_melodrama',
      'play_musical',
      'musical',
      'play_one_person_show',
      'play_opera',
      'play_pantomime',
      'play_performance',
      'play_physical',
      'play_puppets',
      'show',
      'play_slam_poetry',
      'play_scenic_reading',
      'storytelling',
      'play_tragedy',
      'workshop',
      'interactive_theatre',
      'historical',
      'play_drama',
      'tragicomedy',
      'site_specific',
      'shadow_play',
    ],
  },
  exhibitions: {
    id: 7,
    tags: [
      'architecture',
      'exhibitions_classic',
      'exhibition_comics',
      'exhibitions_contemporary',
      'exhibitions_design',
      'drawings',
      'exhibitions_fashion',
      'exhibition_finissage',
      'exhibition_folk_art',
      'exhibitions_graphics',
      'in_city_guided_tour',
      'handicraft',
      'exhibitions_history',
      'exhibitions_illustrations',
      'exhibitions_installations',
      'exhibitions_mixed_media',
      'exhibitions_monographic',
      'exhibitions_new_media',
      'exhibition_objects',
      'festivals_other',
      'exhibitions_paintings',
      'exhibitions_performance',
      'exhibitions_photography',
      'exhibitions_science_technology',
      'projection',
      'exhibitions_sculptures',
      'exhibition_site_specific',
      'exhibition_sound_art',
      'exhibitions_street_art',
      'exhibition_typography',
      'exhibitions_vernisage',
      'exhibitions_video',
      'interactive',
      'intermedia',
      'glass',
      'ceramics',
      'group_exhibition',
      'natural_history',
      'workshop',
    ],
  },
  festivals: {
    id: 10,
    tags: [
      'festivals_art_design',
      'festivals_films',
      'festivals_food',
      'festivals_multigenre',
      'festivals_music',
      'festivals_other',
      'festivals_theatre',
      'children_festival',
    ],
  },
  clubbing: {
    id: 9,
    tags: [
      'party_breakbeat',
      'party_dancehall',
      'party_disco',
      'party_drum_and_bass',
      'party_dub',
      'party_dubstep',
      'party_edm',
      'electro',
      'electro_swing',
      'electronic',
      'funk',
      'party_grime',
      'party_hardstyle',
      'hip_hop',
      'party_house',
      'clubbing_indie',
      'latin_american',
      'party_oldies',
      'pop',
      'rap',
      'rock',
      'tech_house',
      'party_techno',
      'party_trance',
      'party_trap',
      'uk_bass',
      'jungle',
      'psychedelic',
      'dance',
      'concerts_release_party',
      'open_decks',
      'jam_session',
      'indie',
    ],
  },
  film: {
    id: 8,
    tags: [
      'film_action',
      'film_adventure',
      'film_animated',
      'augmented_reality',
      'film_biographical',
      'film_biography',
      'comedy',
      'film_crime',
      'film_czech',
      'film_dance',
      'film_disaster',
      'documentary',
      'drama',
      'film_erotic',
      'experimental',
      'film_exploitation',
      'fairy_tale',
      'film_family',
      'film_fantasy',
      'film_for_children',
      'film_historic',
      'film_horror',
      'musical',
      'film_mystery',
      'film_new',
      'film_noir',
      'film_psychological',
      'film_recording',
      'film_road_movie',
      'film_romantic',
      'film_sci_fi',
      'film_short',
      'film_music',
      'film_student',
      'film_thriller',
      'film_war',
      'film_western',
      'polish_film',
      'slovak_film',
      'sport_film',
    ],
  },
  gastronomy: {
    id: 11,
    tags: [
      'gastronomy_brunch',
      'gastronomy_courses',
      'gastronomy_dinner',
      'gastronomy_market',
      'gastronomy_tasting',
      'gastronomy_vege_vegan',
      'workshop',
      'lunch',
      'wine',
      'breakfast',
    ],
  },
  for_children: {
    id: 16,
    tags: [
      'for_children_educational',
      'for_children_fun_experience',
      'workshop',
      'under_age_3',
    ],
  },
  sport: {
    id: 19,
    tags: [
      'e_gaming',
      'ball_games',
      'participant_sports',
      'spectator_sports',
      'motorsports',
      'extreme_sports',
      'running',
      'yoga',
      'martial_arts',
      'dance_sport',
      'sport_workshop',
    ],
  },
  in_city: {
    id: 20,
    tags: [
      'architecture',
      'in_city_art_and_design',
      'in_city_auction',
      'augmented_reality',
      'in_city_ball',
      'in_city_burlesque',
      'in_city_anniversary_party',
      'in_city_city_markets',
      'in_city_walk',
      'conference',
      'in_city_course',
      'discussion',
      'eco',
      'in_city_educational',
      'in_city_fair',
      'in_city_city_fashion',
      'in_city_fun_and_experience',
      'in_city_game',
      'in_city_guided_tour',
      'handicraft',
      'in_city_history',
      'play_immersive',
      'jam_session',
      'in_city_literature',
      'in_city_meeting',
      'in_city_networking',
      'open_decks',
      'in_city_outdoor',
      'in_city_poetry',
      'in_city_quiz',
      'in_city_release',
      'in_city_science_tech',
      'projection',
      'show',
      'play_stand_up_comedy',
      'in_city_swap',
      'talk',
      'in_city_talk_show',
      'in_city_travels',
      'in_city_webinar',
      'in_city_well_being',
      'workshop',
      'dance_sport',
      'animals_pets',
      'drag',
      'play_slam_poetry',
    ],
  },
} as const;

export type EventCategory = keyof typeof _eventCategories;
// eventCategories can be used to iterate over the categories
export const eventCategories = Object.keys(_eventCategories) as EventCategory[];
export const eventTags = eventCategories.map(
  (category) => _eventCategories[category].tags
);
// All available tags
export type Tag =
  typeof _eventCategories[keyof typeof _eventCategories]['tags'][number];

// Category specific tags
export type ConcertsTag = typeof _eventCategories.concerts.tags[number];
export type PlayTag = typeof _eventCategories.play.tags[number];
export type ExhibitionsTag = typeof _eventCategories.exhibitions.tags[number];
export type FestivalsTag = typeof _eventCategories.festivals.tags[number];
export type ClubbingTag = typeof _eventCategories.clubbing.tags[number];
export type FilmTag = typeof _eventCategories.film.tags[number];
export type GastronomyTag = typeof _eventCategories.gastronomy.tags[number];
export type ForChildrenTag = typeof _eventCategories.for_children.tags[number];
export type InCityTag = typeof _eventCategories.in_city.tags[number];

export const EventCategoryTags = (category: EventCategory) =>
  _eventCategories[category].tags;
export const EventCategoryId = (category: EventCategory) =>
  _eventCategories[category] && _eventCategories[category].id;
export const EventCategoriesWithTags = _eventCategories;
