import * as t from 'io-ts';
import {
  IdIO,
  getLocalesIO,
  EntityStateIO,
  getEntityRelationShip,
  optional,
} from '../common';
import { processEntity } from '../../serialization/entity';

export const VenueAttributesIO = t.interface({
  address: t.string,
  categories: t.array(t.string),
  countryIso: t.string,
  keywords: t.array(t.string),
  latitude: t.number,
  longitude: t.number,
  mainCategory: t.string,
  state: EntityStateIO,
  updatedAt: t.string,
  metaDescription: optional(t.string),
  metaTitle: optional(t.string),
  counts: t.interface({
    followers: optional(t.number),
    events: optional(t.number),
  }),
  email: optional(t.string),
  phone: optional(t.string),
  urlFacebook: optional(t.string),
  sourceUrl: optional(t.string),
  cityFallback: optional(t.string),
});

export const VenueRelationshipsIO = t.interface({
  city: getEntityRelationShip('city'),
  images: t.array(getEntityRelationShip('image')),
  videos: t.array(getEntityRelationShip('video')),
  childVenues: t.array(getEntityRelationShip('venue')),
  similarsSelected: t.array(getEntityRelationShip('venue')),
  similarsEstimated: optional(t.array(getEntityRelationShip('venue'))),
  parentVenue: optional(getEntityRelationShip('venue')),
});

export const VenueIO = t.interface({
  id: IdIO,
  type: t.literal('venue'),
  url: t.string,
  locales: getLocalesIO(
    t.interface({
      name: t.string,
      description: t.string,
      descriptionHtml: t.string,
      siteUrl: optional(t.string),
      metaDescription: optional(t.string),
      metaTitle: optional(t.string),
      noEvents: optional(t.string),
    })
  ),
  relationships: VenueRelationshipsIO,
  attributes: VenueAttributesIO,
});

export type VenueRaw = t.TypeOf<typeof VenueIO>;
const serialize = (entity: VenueRaw) => processEntity(entity, {});
export type Venue = ReturnType<typeof serialize>;
