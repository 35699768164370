import {
  IdIO,
  getLocalesIO,
  EntityStateIO,
  getEntityRelationShip,
  optional,
} from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const EventAttributesIO = t.interface({
  categories: t.array(t.string),
  exhibitionMeta: optional(t.interface({ curator: optional(t.string) })),
  filmMeta: optional(
    t.interface({
      imdb: optional(t.string),
      csfd: optional(t.string),
      filmweb: optional(t.string),
      originalName: optional(t.string),
      released: optional(t.string),
      length: optional(t.string),
      director: optional(t.string),
      author: optional(t.string),
    })
  ),
  schedulesRange: optional(
    t.interface({
      from: t.any,
      to: t.any,
    })
  ),
  keywords: t.array(t.string),
  mainCategory: t.string,
  minorPerformers: t.array(t.string),
  state: EntityStateIO,
  tags: t.array(t.string),
  tagsManual: t.array(t.string),
  sensitive: t.union([t.boolean, t.null]),
  hasTimeSlots: t.boolean,
});

export const EventRelationshipsIO = t.interface({
  contacts: optional(t.array(getEntityRelationShip('contact'))), // TODO: Remove completely once verified it has been removed from this endpoint
  // lot of events have no image, they are taken from performers & venue entity
  images: t.array(getEntityRelationShip('image')),
  performers: t.array(getEntityRelationShip('performer')),
  videos: t.array(getEntityRelationShip('video')),
});

export const EventIO = t.interface({
  id: IdIO,
  type: t.literal('event'),
  url: t.string,
  locales: getLocalesIO(
    t.interface({
      name: t.string,
      note: optional(t.string),
      description: t.string,
      descriptionHtml: t.string,
      metaDescription: optional(t.string),
      metaTitle: optional(t.string),
    })
  ),
  relationships: EventRelationshipsIO,
  attributes: EventAttributesIO,
});

export type EventRaw = t.TypeOf<typeof EventIO>;
const serialize = (entity: EventRaw) => processEntity(entity, {});
export type Event = ReturnType<typeof serialize>;
