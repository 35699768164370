import * as t from 'io-ts';
import { optional } from '../common';
import { ContactIO } from '../entities/Contact';

export const ContactCollectionIO = t.interface({
  included: optional(t.interface({})),
  meta: t.interface({
    donut: optional(t.string),
    nextScrollId: optional(t.string),
  }),
  contacts: t.array(ContactIO),
});

export type ContactCollectionResponse = t.TypeOf<typeof ContactCollectionIO>;
